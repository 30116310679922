import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { addAnimation } from "./components/functions";

document.querySelectorAll(".homepage__stand-out-section").forEach((section) => {
  const wrapper = section.querySelector(".stand-out");
  const items = section.querySelectorAll(".stand-out__item");

  const calc = () => {
    section.style.height = `${items.length * 100}vw`;

    const sectionRect = section.getBoundingClientRect();
    const sectionTop = sectionRect.top;
    const sectionBottom = sectionRect.bottom;
    const sectionHeight = sectionRect.height;

    const wrapperRect = wrapper.getBoundingClientRect();
    const wrapperWidth = wrapperRect.width;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    if (sectionTop >= 0) {
      wrapper.style.transform = `translateX(0)`;
    } else if (sectionBottom <= windowHeight) {
      wrapper.style.transform = `translateX(-${wrapperWidth - windowWidth}px)`;
    } else {
      const min = 0;
      const max = wrapperWidth + windowWidth;

      const translate =
        ((sectionTop / (windowHeight - sectionHeight)) * (max - min)) / 2;

      wrapper.style.transform = `translateX(-${translate}px)`;
    }
  };

  window.addEventListener("scroll", calc);
  window.addEventListener("resize", calc);
});

const meetUsSection = document.querySelector(".homepage__meet-us");
const homeHeroVideo = document.querySelector(".home-hero__video");

if (meetUsSection && homeHeroVideo) {
  const checkElementPosition = () => {
    if (meetUsSection.getBoundingClientRect().top < 70) {
      homeHeroVideo.pause();
      homeHeroVideo.style.opacity = "0";
    } else if (meetUsSection.getBoundingClientRect().top > 30) {
      homeHeroVideo.play();
      homeHeroVideo.style.opacity = "";
    }
  };
  window.addEventListener("scroll", checkElementPosition);
  checkElementPosition();
}

const implementationHeaders = document.querySelectorAll(
  ".implementation-process__step__header"
);

if (implementationHeaders) {
  implementationHeaders.forEach((el) => {
    el.addEventListener("click", () => {
      if (
        el.nextElementSibling.classList.contains(
          "implementation-process__step__content--active"
        )
      ) {
        el.nextElementSibling.classList.remove(
          "implementation-process__step__content--active"
        );
      } else {
        el.nextElementSibling.classList.add(
          "implementation-process__step__content--active"
        );
      }
    });
  });
}

const offerListHeaders = document.querySelectorAll(".offer-list__step__header");

if (offerListHeaders) {
  offerListHeaders.forEach((el) => {
    el.addEventListener("click", () => {
      if (
        el.nextElementSibling.classList.contains(
          "offer-list__step__content--active"
        )
      ) {
        el.nextElementSibling.classList.remove(
          "offer-list__step__content--active"
        );
      } else {
        el.nextElementSibling.classList.add(
          "offer-list__step__content--active"
        );
      }
    });
  });
}

const swiper = new Swiper(".homepage__portfolio__slider", {
  modules: [Navigation],
  direction: "horizontal",
  loop: false,
  slidesPerView: 1.1,
  spaceBetween: 20,
  navigation: {
    nextEl: ".homepage__portfolio__slider--next",
    prevEl: ".homepage__portfolio__slider--prev",
  },
  breakpoints: {
    900: {
      slidesPerView: 2,
    },
    1400: {
      slidesPerView: 3,
    },
    2000: {
      slidesPerView: 4,
    },
  },
});

const swiper2 = new Swiper(".references-section__slider", {
  modules: [Navigation, Autoplay],
  direction: "horizontal",
  loop: true,
  slidesPerView: 1,
  spaceBetween: 20,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
  },
  navigation: {
    nextEl: ".references-section__slider--next",
    prevEl: ".references-section__slider--prev",
  },
});

const homepageMeetUs = document.querySelector(".homepage__meet-us");
const homepageMeetContent = document.querySelector(
  ".homepage__meet-us__content"
);
const homepagePartnersContainer = document.querySelector(".homepage__partners");
const homepageOfferContainer = document.querySelector(".homepage__offer");
const homepagePortfolioContainer = document.querySelector(
  ".homepage__portfolio"
);
const homepageImplementationContainer = document.querySelector(
  ".homepage__implementation-process"
);
const homepageOfferListContainer = document.querySelector(
  ".homepage__offer-list"
);
const homepagePrizesContainer = document.querySelector(".homepage__prizes");
const homepageStandOutContainer = document.querySelector(
  ".homepage__stand-out-section"
);
const referencesContainer = document.querySelector(".references-section");
const homepageBlogContainer = document.querySelector(".homepage__blog");
const homepageContactContainer = document.querySelector(".homepage__contact");

window.addEventListener("scroll", () => {
  if (homepageMeetUs && homepageMeetContent) {
    addAnimation(homepageMeetUs, "homepage__meet-us--active");
  }
  if (homepagePartnersContainer) {
    addAnimation(homepagePartnersContainer, "homepage__partners--active");
  }
  if (homepageOfferContainer) {
    addAnimation(homepageOfferContainer, "homepage__offer--active");
  }
  if (homepagePortfolioContainer) {
    addAnimation(homepagePortfolioContainer, "homepage__portfolio--active");
  }
  if (homepageImplementationContainer) {
    addAnimation(
      homepageImplementationContainer,
      "homepage__implementation-process--active"
    );
  }
  if (homepageOfferListContainer) {
    addAnimation(homepageOfferListContainer, "homepage__offer-list--active");
  }
  if (homepagePrizesContainer) {
    addAnimation(homepagePrizesContainer, "homepage__prizes--active");
  }
  if (homepageStandOutContainer) {
    addAnimation(
      homepageStandOutContainer,
      "homepage__stand-out-section--active"
    );
  }
  if (referencesContainer) {
    addAnimation(referencesContainer, "references-section--active");
  }
  if (homepageBlogContainer) {
    addAnimation(homepageBlogContainer, "homepage__blog--active");
  }
  if (homepageContactContainer) {
    addAnimation(homepageContactContainer, "homepage__contact--active");
  }
  if (homeHeroVideo) {
    homeHeroVideo.style.transform = `translateY(${window.scrollY * 0.5}px)`;
  }
});

const homepageOfferText = document.querySelector(".homepage__offer__text");

if (homepageOfferText) {
  const offerText = homepageOfferText.querySelector(
    ".homepage__offer__paragraph"
  );
  const activator = homepageOfferText.querySelector(
    ".homepage__offer__text__more"
  );
  const html = document.querySelector("html");
  const x = window
    .getComputedStyle(html)
    .getPropertyValue("font-size")
    .slice(0, -2);

  const height = Math.round(130 * (x / 10));

  if (offerText.offsetHeight > height - 2) {
    offerText.style.maxHeight = "13rem";
  }
  if (offerText.offsetHeight < height) {
    activator.style.display = "none";
  }
  activator.addEventListener("click", () => {
    if (offerText.classList.contains("homepage__offer__paragraph--active")) {
      offerText.classList.remove("homepage__offer__paragraph--active");
      activator.classList.remove("homepage__offer__text__more--active");
    } else {
      offerText.classList.add("homepage__offer__paragraph--active");
      activator.classList.add("homepage__offer__text__more--active");
    }
  });
}

const homepagePrizesText = document.querySelector(".homepage__prizes__text");

if (homepagePrizesText) {
  const prizesText = homepagePrizesText.querySelector(
    ".homepage__prizes__paragraph"
  );
  const activator = homepagePrizesText.querySelector(
    ".homepage__prizes__text__more"
  );
  const html = document.querySelector("html");
  const x = window
    .getComputedStyle(html)
    .getPropertyValue("font-size")
    .slice(0, -2);

  const height = Math.round(130 * (x / 10));

  if (prizesText.offsetHeight > height - 2) {
    prizesText.style.maxHeight = "13rem";
  }
  if (prizesText.offsetHeight < height) {
    activator.style.display = "none";
  }
  activator.addEventListener("click", () => {
    if (prizesText.classList.contains("homepage__prizes__paragraph--active")) {
      prizesText.classList.remove("homepage__prizes__paragraph--active");
      activator.classList.remove("homepage__prizes__text__more--active");
    } else {
      prizesText.classList.add("homepage__prizes__paragraph--active");
      activator.classList.add("homepage__prizes__text__more--active");
    }
  });
}
